import { useMutation } from "@apollo/client";
import { CREATE_RECOMMENDED_SCHEDULE_PLAYLIST_MUTATION } from "graphql/mutations";

export function useCreateRecommendedSchedulePlaylist({
  refetchQueries,
  awaitRefetchQueries,
}) {
  const [createRecommendedSchedulePlaylist, { loading }] = useMutation(
    CREATE_RECOMMENDED_SCHEDULE_PLAYLIST_MUTATION,
    {
      refetchQueries,
      awaitRefetchQueries,
    }
  );

  return [
    ({ variables }) => {
      const { date } = variables;

      return createRecommendedSchedulePlaylist({
        variables: { date },
      });
    },
    loading,
  ];
}
