import { useMutation } from "@apollo/client";
import { DATE_FORMAT_FOR_SCHEDULES } from "constants/index";
import { REMOVE_CLASSES_FOR_SCHEDULE_PLAYLIST_MUTATION } from "graphql/mutations";
import moment from "moment";
import { useAddedClasses } from "./useAddedClasses";

export function useRemoveClassFromSchedule({ selectedDate }) {
  const startOfSelectedDate = moment(selectedDate)
    .startOf("day")
    .format(DATE_FORMAT_FOR_SCHEDULES);
  const { addedClasses } = useAddedClasses({ startOfSelectedDate });
  const [removeClassesForSchedule, { loading }] = useMutation(
    REMOVE_CLASSES_FOR_SCHEDULE_PLAYLIST_MUTATION
  );

  return [
    ({ variables }) => {
      const existingClassIds = addedClasses?.classes?.map(c => c.id);

      const { id: playlistId, classIds, fromModule } = variables;
      const newClasses = existingClassIds
        .filter(classId => !classIds.includes(classId))
        .map(classId => ({
          __typename: "PlaylistClass",
          id: classId,
        }));

      return removeClassesForSchedule({
        variables: {
          id: playlistId,
          classIds: classIds.map(id => ({ id })),
          fromModule,
        },
        optimisticResponse: {
          playlist: {
            __typename: "Playlist",
            id: playlistId,
            classes: newClasses,
          },
        },
      });
    },
    loading,
  ];
}
