import { useQuery } from "@apollo/client";
import { GET_ADDED_SCHEDULE_CLASSES } from "graphql/queries";

export function useAddedClasses({
  startOfSelectedDate,
}: {
  startOfSelectedDate: string;
}) {
  const { data: addedClassesData } = useQuery(GET_ADDED_SCHEDULE_CLASSES, {
    variables: { date: startOfSelectedDate },
  });
  const { addedClasses } = addedClassesData || {};

  return {
    addedClasses,
  };
}
