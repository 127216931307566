import { useMutation } from "@apollo/client";
import { REMOVE_TAKEN_CLASS_MUTATION } from "graphql/mutations";

export function useRemoveTakenClass({ refetchQueries, awaitRefetchQueries }) {
  const [removeTakenClass] = useMutation(REMOVE_TAKEN_CLASS_MUTATION, {
    refetchQueries,
    awaitRefetchQueries,
  });

  return [
    ({ variables }) => {
      const { date, classIdentifier } = variables;

      return removeTakenClass({
        variables: { date, classIdentifier },
      });
    },
  ];
}
