import React from "react";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import Icon, { CircleX, DragAndDrop } from "app/components/Icon";
import Div from "app/components/Div";
import Flex from "app/components/Flex";
import ClassRow from "app/components/ClassRow";
import { debounce } from "lodash";
import Loader from "app/components/Loader";
import { useRemoveClassFromSchedule, useUpdateSchedule } from "./hooks";

interface Props {
  selectedDate: any;
  selectedPlaylist: any;
}

export const EditClasses = ({ selectedDate, selectedPlaylist = {} }: Props) => {
  const [
    removeClassesMutation,
    removeClassesMutationLoading,
  ] = useRemoveClassFromSchedule({ selectedDate });
  const [
    updateScheduleMutation,
    updateScheduleMutationLoading,
  ] = useUpdateSchedule();
  const classes = selectedPlaylist?.classes;
  const isLoading =
    removeClassesMutationLoading || updateScheduleMutationLoading;

  const updateSchedule = classes => {
    const classIds = classes.map(c => String(c.id));
    updateScheduleMutation({
      variables: {
        fromModule: "Playlist",
        id: selectedPlaylist?.id,
        classIds,
      },
    });
  };

  const removeClasses = debounce(classes => {
    const classIds = classes.map(c => String(c.id));
    removeClassesMutation({
      variables: {
        id: selectedPlaylist?.id,
        classIds,
        fromModule: "Playlist",
      },
    });
  }, 200);

  return (
    <Div position="relative" flex={1} width="100%" height="100%">
      <Div
        position={{ _: "relative", lg: "absolute" }}
        height="100%"
        width="100%"
      >
        <DragDropContext
          onDragEnd={({ draggableId: classId, destination, source }) => {
            if (destination?.index >= 0) {
              const changingClassData = classes.find(
                c => parseInt(c.id) === parseInt(classId)
              );
              const cloneEditableClasses = [...classes];
              cloneEditableClasses.splice(source.index, 1);
              cloneEditableClasses.splice(
                destination.index,
                0,
                changingClassData
              );
              updateSchedule(cloneEditableClasses);
            }

            return null;
          }}
        >
          {classes && (
            <Droppable droppableId="droppable-id">
              {({ droppableProps, placeholder, innerRef }) => (
                <Div
                  position="relative"
                  height="100%"
                  ref={innerRef}
                  overflow="auto"
                  {...droppableProps}
                >
                  {classes.map((classData, index) => {
                    return (
                      <Draggable
                        key={classData.id}
                        draggableId={String(classData.id)}
                        index={index}
                      >
                        {({
                          innerRef: draggableRef,
                          draggableProps,
                          dragHandleProps,
                        }) => (
                          <Flex
                            borderRadius="2px"
                            mb="10px"
                            cursor="pointer"
                            width="100%"
                            ref={draggableRef}
                            {...draggableProps}
                          >
                            <Flex
                              justifyContent="center"
                              alignItems="center"
                              p={3}
                            >
                              {isLoading ? (
                                <Loader height={20} width={20} />
                              ) : (
                                <Icon
                                  color="monochrome.2"
                                  height="20px"
                                  as={CircleX}
                                  cursor="pointer"
                                  onClick={() => {
                                    removeClasses([classData]);
                                  }}
                                />
                              )}
                            </Flex>
                            <ClassRow classData={classData} textColor="white" />
                            <Flex
                              justifyContent="center"
                              alignItems="center"
                              p={3}
                              {...dragHandleProps}
                            >
                              <Icon
                                color="monochrome.2"
                                height="20px"
                                as={DragAndDrop}
                              />
                            </Flex>
                          </Flex>
                        )}
                      </Draggable>
                    );
                  })}
                  {placeholder}
                </Div>
              )}
            </Droppable>
          )}
        </DragDropContext>
      </Div>
    </Div>
  );
};
