import { useMutation } from "@apollo/client";
import { ADD_CLASSES_FOR_SCHEDULE_PLAYLIST_MUTATION } from "graphql/mutations";
import { ModifyPlaylistInputV2 } from "services/graphql";

export function useAddClassesForSchedule() {
  const [addClassesForSchedule, { loading }] = useMutation(
    ADD_CLASSES_FOR_SCHEDULE_PLAYLIST_MUTATION
  );

  return [
    ({ variables, ...mutationProps }: { variables: ModifyPlaylistInputV2 }) => {
      const { id: playlistId, classIds, fromModule } = variables;

      return addClassesForSchedule({
        variables: {
          id: playlistId,
          classIds: classIds.map(id => ({ id: String(id) })),
          fromModule,
        },
        ...mutationProps,
      });
    },
    loading,
  ];
}
