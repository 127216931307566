import { useMutation } from "@apollo/client";
import { UPDATE_SCHEDULE_PLAYLIST_MUTATION } from "graphql/mutations";
import { ClassIdentifier, ModifyPlaylistInputV2 } from "services/graphql";

export function useUpdateSchedule() {
  const [updateSchedule, { loading }] = useMutation(
    UPDATE_SCHEDULE_PLAYLIST_MUTATION
  );

  return [
    ({ variables }: { variables: ModifyPlaylistInputV2 }) => {
      const { id: playlistId, classIds } = variables;
      const updatedClasses = classIds.map((classId: ClassIdentifier) => ({
        __typename: "PlaylistClass",
        id: classId,
      }));

      return updateSchedule({
        variables: {
          id: playlistId,
          classIds: classIds.map(id => ({ id })),
        },
        optimisticResponse: {
          updateSchedulePlaylistV2: {
            __typename: "Playlist",
            id: playlistId,
            classes: updatedClasses,
          },
        },
      });
    },
    loading,
  ];
}
