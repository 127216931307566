import { useMutation } from "@apollo/client";
import { UPDATE_WEEKLY_STREAK_GOAL_MUTATION } from "graphql/mutations";

export function useUpdateWeeklyStreak({ refetchQueries }) {
  const [updateWeeklyStreak] = useMutation(UPDATE_WEEKLY_STREAK_GOAL_MUTATION, {
    awaitRefetchQueries: true,
    refetchQueries,
  });

  return [
    ({ variables }) => {
      const { streakGoal } = variables;
      return updateWeeklyStreak({
        variables: { streakGoal },
      });
    },
  ];
}
